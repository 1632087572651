import { JSONSchemaType } from 'ajv';
import { ICreateCostPlusSettings } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createCostPlusSettingScheme: JSONSchemaType<ICreateCostPlusSettings> = {
  type: 'object',
  required: ['siteId', 'type', 'isActive'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    type: {
      default: 'department',
      isNotEmpty: true,
      type: 'string',
      enum: [
        'cpu',
        'department',
        'site',
        'overtime-premium',
        'static',
        'site_with_external_hours',
        'custom',
      ],
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        enum: i18n.t('error.validation.enum', ({
          values:
            'cpu, department, site, overtime-premium, static, site_with_external_hours, custom',
        } as unknown) as string),
      },
    },
    isActive: {
      nullable: false,
      type: 'boolean',
      default: false,
    },
    description: {
      type: 'string',
      nullable: true,
      errorMessage: {
        format: i18n.t('error.validation.string'),
      },
    },
  },
};
