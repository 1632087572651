import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyStepper,
  DrawerBodyStepperContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { StepStatus } from 'src/components/_ui-kit/Steps';
import { ProgressStep } from 'src/components/_ui-kit/Steps/ProgressStep';
import { useFormik } from 'formik';
import {
  useBrowserHistoryFunctions,
  useEmployeeComboboxesDataFetchersList,
  useValidate,
} from 'src/modules/utils';
import { createEmployeeScheme } from 'src/modules/schemes/employees';
import { useDispatch } from 'react-redux';
import { createEmployeeRequest } from 'src/modules/actions';
import {
  DATETIME_FORMAT_TO_PASS,
  composeDate,
  format,
} from 'src/modules/utils/dateWrapper';
import { IFilterData, IWhere } from 'src/modules/types';
import { CreateEmployeeRequireFields } from './formFields/CreateEmployeeRequiredFields';
import { CreateEmployeeOptionalFields } from './formFields/CreateEmployeeOptionalFields';

const initialValues = {
  employeeId: '',
  siteId: NaN,
  positionId: NaN,
  staffingId: NaN,
  supervisorId: NaN,
  defaultShiftId: NaN,
  defaultDepartmentId: NaN,
  firstName: '',
  lastName: '',
  payRate: NaN,
  dateHired: null,
  badge: NaN,

  // Optional properties
  active: true,
  address: '',
  phonenumber: '',
  dateentered: null,
  last4SSN: undefined,
  wmsLogin: '',
  taktId: '',
  canRehire: true,
  wotc: false,
  emergencyContact: '',
  comments: '',
};

const stepsIds = {
  requiredFields: 'requiredFields' as const,
  optionalFields: 'optionalFields' as const,
};

const initialSteps = {
  [stepsIds.requiredFields]: 'incomplete' as const,
  [stepsIds.optionalFields]: 'incomplete' as const,
};

interface ICreateEmployeeProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

const requiredFieldsNames = [
  'employeeId',
  'siteId',
  'positionId',
  'staffingId',
  'supervisorId',
  'firstName',
  'lastName',
  'payRate',
  'dateHired',
  'badge',
  'defaultShiftId',
  'defaultDepartmentId',
];

export const CreateEmployee = ({
  isOpen,
  onClose,
  filterCount,
  filterList,
}: ICreateEmployeeProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { pushSearchObject } = useBrowserHistoryFunctions();

  const comboboxesDataFetchersList = useEmployeeComboboxesDataFetchersList();

  const stepsStaticData = React.useMemo(
    () => ({
      [stepsIds.requiredFields]: {
        id: stepsIds.requiredFields,
        title: t('common.required_details'),
        subtitle: t('employees.few_details_about_your_employee'),
      },
      [stepsIds.optionalFields]: {
        id: stepsIds.optionalFields,
        title: t('common.optional_details'),
        subtitle: t('common.additional_information'),
      },
    }),
    [t],
  );

  const [currentStepName, setCurrentStepName] = React.useState<
    typeof stepsIds[keyof typeof stepsIds]
  >(stepsIds.requiredFields);
  const [stepsStatuses, setStepsStatuses] = React.useState<
    {
      [key in typeof stepsIds[keyof typeof stepsIds]]: StepStatus;
    }
  >(initialSteps);

  const validate = useValidate(createEmployeeScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (data) => {
      if (currentStepName === 'optionalFields') {
        pushSearchObject({});

        dispatch(
          createEmployeeRequest({
            data: {
              ...data,
              payRate: Number(data.payRate),
              inactivatedAt: data.active
                ? null
                : composeDate(new Date(), format(DATETIME_FORMAT_TO_PASS)),
            },
            filters: {
              list: filterList,
              count: filterCount,
            },
          }),
        );

        comboboxesDataFetchersList.forEach((func) => {
          func();
        });

        onClose();

        formik.resetForm();

        setCurrentStepName(stepsIds.requiredFields);
      } else {
        setStepsStatuses((prev) => ({
          ...prev,
          [stepsIds.requiredFields]: 'complete',
        }));
        setCurrentStepName(stepsIds.optionalFields);
      }
    },
  });

  const onStepClick = (stepId: typeof stepsIds[keyof typeof stepsIds]) => {
    setCurrentStepName(stepId);
  };

  const onReset = () => {
    formik.resetForm();
    setStepsStatuses(initialSteps);
  };

  React.useEffect(() => {
    const isRequiredFieldsHasError = Object.keys(formik.errors).some(
      (fieldName) =>
        formik.touched[fieldName] && requiredFieldsNames.includes(fieldName),
    );

    if (isRequiredFieldsHasError) {
      setStepsStatuses((prev) => ({
        ...prev,
        requiredFields: 'error',
      }));
    }
  }, [formik.errors, formik.touched]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      {isOpen && (
        <>
          <DrawerHeader onCloseClick={onClose}>
            {t('employees.create')}
          </DrawerHeader>
          <DrawerBody>
            <DrawerBodyStepper>
              {Object.entries(stepsStatuses).map(([stepName, stepStatus]) => (
                <ProgressStep
                  key={stepName}
                  onClick={onStepClick}
                  status={stepName === currentStepName ? 'current' : stepStatus}
                  {...stepsStaticData[stepName]}
                />
              ))}
            </DrawerBodyStepper>
            <DrawerBodyStepperContent>
              {currentStepName === stepsIds.requiredFields && (
                <CreateEmployeeRequireFields formik={formik} />
              )}
              {currentStepName === stepsIds.optionalFields && (
                <CreateEmployeeOptionalFields formik={formik} />
              )}
            </DrawerBodyStepperContent>
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar
              onReset={onReset}
              onApply={formik.handleSubmit}
              onCancel={onClose}
            />
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
