import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

import { TableOrder } from 'src/components/EnhancedTable';

import { AnyObject, IFilter, IFilterData } from '../../../types';
import { IFilterItem } from '../../../../components/Filter';

/**
 * Check if the filter has any conditions from modal form
 * @param filter is similar to 'filter' structure of loopback 4 framework
 * https://loopback.io/doc/en/lb4/Include-filter.html
 */
export const hasFormFilters = (filter: IFilterData | undefined): boolean => {
  if (filter) {
    // Check filters for direct entity
    if (!isEmpty(filter.where)) {
      return true;
    }
    // Check filters for each of relations
    if (filter.include) {
      for (const filterItem of filter.include) {
        if (!isEmpty(filterItem.scope)) {
          if (hasFormFilters(filterItem.scope)) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const defaultOrderDetails = {
  orderBy: 'id' as const,
  order: 'desc' as TableOrder,
};

export const defaultListOrder = ['createdAt ASC'];

// Filter object if passed properties equal Null
export const omitIfNull = <
  T extends Record<string, unknown>,
  K extends Array<keyof T>
>(
  object: T,
  fieldsArray: K,
) => {
  return reduce(
    object,
    (acc, cur, ind) => {
      const included = fieldsArray.includes(ind);
      if ((included && cur !== null) || !included) {
        acc[ind as keyof T] = cur;
      }
      return acc;
    },
    {} as T,
  );
};

export const filterForGlobalSitesDropdown = {
  filter: {
    fields: {
      id: true,
      name: true,
      uuid: true,
    },
    include: [{ relation: 'client' }],
    order: ['name asc'],
    where: {
      archived: { eq: false },
    },
  },
};

export const mergeFilters = (...filters: IFilter[]): IFilter => {
  return mergeWith({}, ...filters, (a: AnyObject, b: AnyObject) => {
    if (isArray(a)) {
      return b.concat(a);
    }
  });
};

export const getSiteIdPath = (filters: IFilterItem[] = []): string => {
  const siteIdFilter = filters.find(({ type }) => type === 'comboboxSites');

  return siteIdFilter?.name.replace(/\./g, ',') ?? '';
};
