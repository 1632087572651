import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateSiteImportingSettingsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { map, omit } from 'lodash';
import ClientKeysComboBox from 'src/components/Formik/comboboxes-with-entities/ClientKeysCombobox';
import { updateSiteImportingSettingScheme } from 'src/modules/schemes';
import { getSiteImportingSettingsTable } from 'src/modules/selectors/site-importing-settings.selector';

interface IUpdateImportingSettingProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  entitiesIds: IdsArray;
}

export const UpdateImportingSetting = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  entitiesIds,
}: IUpdateImportingSettingProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allEntities = useSelector(getSiteImportingSettingsTable);

  const entitiesToUpdate = allEntities
    .filter((entity) => entitiesIds.includes(entity.id))
    .map((item) => ({
      ...omit(item, 'site'),
      isLocalInfile: Boolean(item.isLocalInfile),
      transformOnSync: Boolean(item.transformOnSync),
    }));

  const validate = useValidate(updateSiteImportingSettingScheme);

  const formik = useFormik({
    initialValues: entitiesToUpdate,
    enableReinitialize: true,
    validate,
    onSubmit: (data) => {
      dispatch(
        updateSiteImportingSettingsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('site-importing-settings.bulk_update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {map(formik.values, (siteImportingSetting, index) => (
              <DrawerBodySectionContent key={siteImportingSetting.id}>
                <DrawerBodySectionTitle>{index + 1}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteUuid`}
                    fieldAsId="uuid"
                    required={true}
                    formik={formik}
                    label={t('site-importing-settings.site')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <ClientKeysComboBox
                    id={`${index}.clientKey`}
                    required={true}
                    formik={formik}
                    label={t('site-importing-settings.clientKey')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                {map(
                  [
                    'productionRepositoryKey',
                    'weeklyCpuKey',
                    'customCostPlusKey',
                    'transformerKey',
                    'aggregationKey',
                    'costPlusByExternalHoursServiceKey',
                    'fileRegex',
                    'bucket',
                    'tableType',
                    'hoursRepositoryKey',
                    'googleCloudKeyPath',
                  ],
                  (key) => (
                    <FormFieldContainer key={key}>
                      <FormikTextField
                        id={`${index}.${key}`}
                        variant="outlined"
                        required={
                          ![
                            'hoursRepositoryKey',
                            'googleCloudKeyPath',
                            'costPlusByExternalHoursServiceKey',
                          ].includes(key)
                        }
                        fullWidth
                        label={t(`site-importing-settings.${key}`)}
                        name={`${index}.${key}`}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>
                  ),
                )}

                <FormFieldContainer>
                  <FormikCheckbox
                    id={`${index}.transformOnSync`}
                    formik={formik}
                    label={t('site-importing-settings.transformOnSync')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCheckbox
                    id={`${index}.isLocalInfile`}
                    formik={formik}
                    label={t('site-importing-settings.isLocalInfile')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
