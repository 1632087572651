import { JSONSchemaType } from 'ajv';
import { ICreateSiteImportingSettings } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createSiteImportingSettingScheme: JSONSchemaType<ICreateSiteImportingSettings> = {
  type: 'object',
  required: [
    'clientKey',
    'siteUuid',
    'productionRepositoryKey',
    'weeklyCpuKey',
    'customCostPlusKey',
    'transformerKey',
    'aggregationKey',
    'fileRegex',
    'tableType',
    'bucket',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    clientKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    siteUuid: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    productionRepositoryKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    weeklyCpuKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    customCostPlusKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    transformerKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    aggregationKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    hoursRepositoryKey: {
      type: 'string',
      nullable: true,
    },
    costPlusByExternalHoursServiceKey: {
      type: 'string',
      nullable: true,
    },
    googleCloudKeyPath: {
      type: 'string',
      nullable: true,
    },
    transformOnSync: {
      type: 'boolean',
      default: true,
    },
    isLocalInfile: {
      type: 'boolean',
      nullable: true,
    },
    fileRegex: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    tableType: {
      default: 'production',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    bucket: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
