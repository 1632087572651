import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  useFilledAssociates,
  useFilledAssociatesComposeWhere,
  useFilledAssociatesTableFiltersConfiguration,
} from 'src/modules/utils/hooks/filled-associates.hooks';
import { FilledAssociatesFilter } from 'src/pages/FilledAssociates/components/FilledAssociatesFilter';
import { ReportPage } from 'src/components/ReportPage';

const reportUrl = '/sites/filled-associates';

export const FilledAssociates: React.FC = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl);

  const filterFieldsConfiguration = useFilledAssociatesTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useFilledAssociatesComposeWhere({
    siteId: +filterFields.siteId.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isCountDataLoading,
    countData,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useFilledAssociates(reportUrl, where);

  return (
    <ReportPage hideNoData>
      <PageContentWithTopToolbar>
        <PageContentChildContainer
          fullHeight={false}
          sx={{ pl: 0, maxWidth: 'calc(100% - 16px)' }}
        >
          <TableToolbar>
            <TableTitle>
              {t('main_menu.chart_reports.filled_associates')}
            </TableTitle>

            <TableActions>
              <FilledAssociatesFilter
                getLabel={getLabel}
                onFiltersFormSubmit={onFiltersFormSubmit}
                getFilterCommonPropsByFilterName={
                  getFilterCommonPropsByFilterName
                }
              />

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={requestFilters}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>

        <ReportTable
          data={data.items as any}
          summaryData={data.summary}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
          sx={{ maxWidth: 'calc(100% - 32px)', flexGrow: 0 }}
        />
      </PageContentWithTopToolbar>
    </ReportPage>
  );
};
