import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  siteId?: number;
  employeeId?: number;
  year?: number;
  week?: number;
}

const reportUrl = '/nfi/weekly-uph/by-site-and-employee';

export const WeeklyUphBySiteAndEmployee: React.FC<IProps> = ({
  siteId,
  employeeId,
  year,
  week,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      employeeId,
      year,
      week,
    }),
    [siteId, employeeId, year, week],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('productions_uph_reports.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('productions_uph_reports.week'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year_week'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'parentSiteName',
        orderConfig: { orderBy: 'parentSiteName' },
        label: t('productions_uph_reports.parentSiteName'),
      },
      {
        id: 'employee',
        orderConfig: { orderBy: 'employee' },
        label: t('productions_uph_reports.employee'),
      },
      {
        id: 'category',
        orderConfig: { orderBy: 'category' },
        label: t('productions_uph_reports.category'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'hrs',
        orderConfig: { orderBy: 'hrs' },
        label: t('productions_uph_reports.hrs'),
      },
      {
        id: 'uph',
        orderConfig: { orderBy: 'uph' },
        label: t('productions_uph_reports.uph'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        where,
        order: [
          `${orderBy ?? defaultOrderDetails.orderBy} ${
            order ?? defaultOrderDetails.order
          }`,
        ],
      }),
      [
        defaultOrderDetails.order,
        defaultOrderDetails.orderBy,
        order,
        orderBy,
        where,
      ],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title) ?? item.title;
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <ReportTable
      data={data.items as any}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey={reportUrl}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
