import React from 'react';
import { FormikConfig, useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { chain, get, map } from 'lodash';

import { useSiteComboboxFilter } from '../sites';
import { getComboboxSitesImportingList } from '../../../selectors/site-importing-settings.selector';

import { ComboBoxOption } from '../../../../components/ComboBox';

import { AnyObject, SiteComboboxOption } from '../../../types';
import * as actions from '../../../actions';
import { ClientKey } from 'src/modules/constants';

export const useUpdateFormLocalState = <T extends { id: number }>(
  entities: T[],
): [T[], React.Dispatch<React.SetStateAction<T[]>>] => {
  const [entitiesLocalState, setEntitiesLocalState] = React.useState(entities);

  React.useEffect(() => {
    setEntitiesLocalState((prevEntitiesState) => {
      return updateEntitiesState(entities, prevEntitiesState);
    });
  }, [entities]);

  return [entitiesLocalState, setEntitiesLocalState];
};

export const useFormikInUpdateForm = <T extends { id: number }>(
  config: FormikConfig<T[]>,
) => {
  const formik = useFormik({
    ...config,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return formik;
};

function updateEntitiesState<T extends { id: number }>(
  entities: T[],
  prevEntitiesState: T[],
) {
  return entities.map((entity) => {
    const prevEntityStateIndex = prevEntitiesState.findIndex(
      (prevEntityState) => prevEntityState.id === entity.id,
    );

    return prevEntityStateIndex !== -1
      ? {
          ...entity,
          ...prevEntitiesState[prevEntityStateIndex],
        }
      : entity;
  });
}

export const useIsObjectChanged = <T extends Record<string, unknown>>(
  data: T,
) => {
  const initialDataRef = React.useRef<T>(data);

  React.useEffect(() => {
    initialDataRef.current = data;

    // this hook only calls on form mount to persist initial form data
    // we need it because some forms not empty by default
    // so in later updates we will compare initial data with new one
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialDataRef.current) {
    return false;
  }

  return Object.entries(data).some(([fieldName, fieldValue]) => {
    return (
      !Number.isNaN(fieldValue) &&
      initialDataRef.current[fieldName] !== fieldValue
    );
  });
};

export const useIsArrayChanged = <T extends Record<string, unknown>>(
  data: T[],
) => {
  const initialDataRef = React.useRef<T[]>(data);

  React.useEffect(() => {
    initialDataRef.current = data;

    // this hook only calls on form mount to persist initial form data
    // we need it because some forms not empty by default
    // so in later updates we will compare initial data with new one
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialDataRef.current || !initialDataRef.current.length) {
    return false;
  }

  return data.some((item, i) =>
    Object.entries(item).some(([fieldName, fieldValue]) => {
      return (
        !Number.isNaN(fieldValue) &&
        initialDataRef.current[i] &&
        initialDataRef.current[i][fieldName] !== fieldValue
      );
    }),
  );
};

export const useSitesByClientCombobox = (
  fieldAsId = 'id',
  clientKey?: ClientKey,
  useReports?: boolean,
) => {
  const dispatch = useDispatch();
  const siteComboboxFilter = useSiteComboboxFilter();

  // make request to fetch clients from the server if we don't have them in the store
  React.useEffect(() => {
    dispatch(actions.getSiteImportingComboboxListRequest(siteComboboxFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sites: AnyObject[] = useSelector(
    getComboboxSitesImportingList,
    shallowEqual,
  );

  return (React.useMemo(() => {
    if (!clientKey) {
      return map(sites, (site) => ({
        id: site[useReports ? 'name' : fieldAsId],
        name: `${site.name} (${get(site, 'client.name')})`,
      }));
    }

    const filteredSites = sites.filter(
      (site) => site.client.clientKey === clientKey,
    ) as SiteComboboxOption[];

    return map(filteredSites, (site) => ({
      id: site[useReports ? 'name' : fieldAsId],
      name: `${site.name} (${get(site, 'client.name')})`,
    }));
  }, [clientKey, fieldAsId, sites, useReports]) as unknown) as ComboBoxOption[];
};

export const useSitesTableTypesCombobox = (clientKey?: ClientKey) => {
  const dispatch = useDispatch();
  const siteComboboxFilter = useSiteComboboxFilter(clientKey);

  // make request to fetch clients from the server if we don't have them in the store
  React.useEffect(() => {
    dispatch(actions.getSiteImportingComboboxListRequest(siteComboboxFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sites: AnyObject[] = useSelector(
    getComboboxSitesImportingList,
    shallowEqual,
  );

  return (React.useMemo(() => {
    const filteredSites = sites.filter(
      (site) => site.client.clientKey === clientKey,
    ) as SiteComboboxOption[];

    return filteredSites.reduce<ComboBoxOption[]>((acc, cur) => {
      acc = [
        ...acc,
        ...chain((cur as AnyObject).importingSettings)
          .map((s) => ({
            id: s.tableType,
            name: s.tableType,
          }))
          .uniq()
          .value(),
      ];

      return acc;
    }, []);
  }, [clientKey, sites]) as unknown) as ComboBoxOption[];
};
