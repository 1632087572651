import React from 'react';
import { createFilterOptions } from '@mui/joy/Autocomplete';

import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { AnyObject } from 'src/modules/types';

interface CreatableComboBoxOption extends ComboBoxOption {
  inputValue?: string;
}

const filter = createFilterOptions<CreatableComboBoxOption>();

const CreatableComboBox: React.FC<IComboboxProps> = (props) => {
  const [value, setValue] = React.useState<CreatableComboBoxOption | null | ''>(
    null,
  );

  const handleFilterOptions = (
    options: CreatableComboBoxOption[],
    params: any,
  ) => {
    const filtered = filter(options, params);

    const { id, inputValue } = params;
    // Suggest the creation of a new value
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        id,
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: CreatableComboBoxOption | string | number | null,
  ) => {
    if (value && (value as CreatableComboBoxOption).inputValue) {
      // Create a new value from the user input
      setValue({
        id: ((value as CreatableComboBoxOption)
          .inputValue as unknown) as number,
        name: (value as CreatableComboBoxOption).inputValue as string,
      });

      if (props.onChange) {
        (props as AnyObject).onChange(
          e,
          {
            ...(value as CreatableComboBoxOption),
            id:
              (value as CreatableComboBoxOption).inputValue ??
              (value as CreatableComboBoxOption).id,
          } ?? null,
        );
      }
    } else {
      setValue((value ?? '') as CreatableComboBoxOption);
      if (props.onChange) {
        (props as AnyObject).onChange(e, value ?? null);
      }
    }
  };

  const handleGetOptionLabel = (option: CreatableComboBoxOption | string) => {
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  const getOptionSelected = (option: ComboBoxOption, value: ComboBoxOption) =>
    option.id === value.id;

  return (
    <ComboBox
      {...props}
      value={(value ?? props.value) as CreatableComboBoxOption}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      onChange={handleChange}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
};

export default CreatableComboBox;
