import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import { get, isNumber } from 'lodash';
import { Grid, Button, FormControl } from '@mui/joy';

import { AnyObject } from 'src/modules/types';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikLangCombobox from 'src/components/Formik/FormikLangCombobox';
import FormikMultipleSelect from 'src/components/Formik/FormikMultipleSelect';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import SupervisorComboBox from 'src/components/Formik/comboboxes-with-entities/SupervisorComboBox';
import { ComboBoxOption } from 'src/components/ComboBox';
import { useGetUserFieldsByRole } from 'src/modules/utils/hooks/users';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { MultipleSelectOption } from 'src/components/Formik/FormikMultipleSelectWithSearch';
import { MUI_SIZE_12, MUI_SIZE_2, MUI_SIZE_3, MUI_SIZE_9 } from 'src/config';
import { useFetchUsersSitesCombobox } from 'src/modules/utils/hooks/sites';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentRole } from '../../selectors/auth';
import { getSitesComboboxList } from '../../selectors/site';

interface IRenderUserOtherFields<Values> {
  formik: FormikHelpers<Values> & FormikState<Values>;
  shifts: ComboBoxOption[];
  departments: ComboBoxOption[];
  sites?: MultipleSelectOption[];
  staffingProviders?: ComboBoxOption[];
  clients?: ComboBoxOption[];
}

export const useRenderUserOtherFields = <Values extends AnyObject>({
  sites,
  clients,
  shifts,
  staffingProviders,
  formik,
  departments,
}: IRenderUserOtherFields<Values>) => {
  const { t } = useTranslation();
  const getConfig = useGetUserFieldsByRole();

  const fetchSitesCombobox = useFetchUsersSitesCombobox();
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const allSites = useSelector(getSitesComboboxList, shallowEqual);

  const handleApplyToAllSites = (fieldName: string) => () => {
    formik.setFieldValue(
      fieldName,
      allSites.map((s) => s.id),
    );
  };

  const getFields = React.useCallback(
    (index?: number) => {
      const role = isNumber(index)
        ? get(formik.values, [index, 'role'])
        : formik.values.role;

      const commonFields = getConfig(role).map((i) => {
        const fieldName = isNumber(index) ? `${index}.${i.name}` : i.name;

        switch (i.type) {
          case 'email':
            return (
              <FormFieldContainer>
                <FormikTextField
                  key={fieldName}
                  variant="outlined"
                  required={i.required}
                  disabled={isNumber(index)}
                  fullWidth
                  id={fieldName}
                  label={i.label}
                  name={fieldName}
                  autoComplete={fieldName}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'text':
            return (
              <FormFieldContainer>
                <FormikTextField
                  key={fieldName}
                  variant="outlined"
                  required={i.required}
                  fullWidth
                  id={fieldName}
                  label={i.label}
                  name={fieldName}
                  autoComplete={fieldName}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'lang':
            return (
              <FormFieldContainer>
                <FormikLangCombobox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'sites':
            return (
              <FormFieldContainer>
                <Grid container spacing={MUI_SIZE_2} alignItems="flex-end">
                  <Grid xs={MUI_SIZE_12} md={MUI_SIZE_9}>
                    <FormikMultipleSelect
                      key={fieldName}
                      id={fieldName}
                      title={i.label}
                      formik={formik}
                      required={i.required}
                      options={sites ?? []}
                    />
                  </Grid>
                  <Grid xs={MUI_SIZE_12} md={MUI_SIZE_3}>
                    <FormControl>
                      <Button onClick={handleApplyToAllSites(fieldName)}>
                        {t('users.apply_to_all')}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormFieldContainer>
            );
          default:
            return null;
        }
      });

      const fields = getConfig(role).map((i) => {
        const fieldName = isNumber(index) ? `${index}.${i.name}` : i.name;

        switch (i.type) {
          case 'shift':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  id={fieldName}
                  required={i.required}
                  label={i.label}
                  placeholder={i.label}
                  options={shifts}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'department':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  id={fieldName}
                  required={i.required}
                  label={i.label}
                  placeholder={i.label}
                  options={departments}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'supervisor':
            return (
              <FormFieldContainer>
                <SupervisorComboBox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  siteId={
                    isNumber(index)
                      ? formik.values[index].sites
                      : formik.values.sites
                  }
                  label={i.label}
                  placeholder={i.label}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'staffings':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  label={i.label}
                  placeholder={i.label}
                  options={staffingProviders ?? []}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'clients':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  label={i.label}
                  placeholder={i.label}
                  options={clients ?? []}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          default:
            return null;
        }
      });

      return [...commonFields, ...fields];

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getConfig, formik.values, formik.errors, formik.submitCount],
  );

  return (index?: number) => getFields(index);
};
