import { memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';

import { IdsArray, IStoreState, IUpdateOtherCosts } from 'src/modules/types';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export const getOtherCostsRefreshKey = (state: IStoreState) =>
  state.otherCosts.refreshKey;

/**
 * Get server error
 * @param otherCosts - State otherCosts
 */
export const getServerError = ({ otherCosts }: IStoreState) => otherCosts.error;

/**
 * Get otherCosts list
 * @param otherCosts - State otherCosts
 */
export const getOtherCostsListAll = ({ otherCosts }: IStoreState) =>
  otherCosts.list;

export const getOtherCostsList = createSelector(
  getOtherCostsListAll,
  getGloballySelectedSites,
  (list, selectedSites) =>
    list.filter(({ siteId }) => selectedSites.includes(siteId)),
);

/**
 * Get otherCosts by array of ids
 * @param otherCosts - State otherCosts
 */
export const getOtherCostsByIds = createSelector(
  getOtherCostsList,
  (otherCosts) =>
    memoize((ids: IdsArray) => {
      const initial: IUpdateOtherCosts[] = [];
      return reduce(
        otherCosts,
        (acc, cur) => {
          if (ids.includes(cur.id)) {
            const otherCostsData = omit(cur, ['site']);
            acc.push(otherCostsData as IUpdateOtherCosts);
          }
          return acc;
        },
        initial,
      );
    }),
);

/**
 * Get otherCosts count
 * @param otherCosts - State otherCosts
 */
export const getOtherCostsCount = ({ otherCosts }: IStoreState) =>
  otherCosts.count;

export const getIsOtherCostsDataLoading = ({
  otherCosts: {
    is_data_requested,
    is_other_costs_list_loading,
    is_other_costs_count_loading,
  },
}: IStoreState) =>
  is_data_requested ||
  is_other_costs_list_loading ||
  is_other_costs_count_loading;
