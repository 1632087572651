import React, { ChangeEvent } from 'react';
import { SxProps } from '@mui/joy/styles/types';
import {
  ITableBodyItem,
  ITableContentProps,
  ITableProps,
  Pagination,
  TableBottom,
  TableContent,
  TableComponent,
} from 'src/components/Table';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  ITableSummaryProps,
  TableSummary,
} from 'src/components/Table/components/TableSummary';
import {
  PAGE_DEFAULT,
  ROWS_PER_PAGE_DEFAULT,
} from 'src/components/EnhancedTable';
import { noop } from 'lodash';
// @@TODO: we might need to use this table instead of ReportWithOwnState table
// if that table is used only for reports
type IReportTableProps<T extends ITableBodyItem> = ITableContentProps<T> &
  Omit<ITableProps, 'children'> & {
    isDataLoading?: boolean;
    summaryData?: Array<ITableSummaryProps>;
    fullHeight?: boolean;
    heightWithTabs?: boolean;
    sx?: SxProps;
    disablePagination?: boolean;
    count?: number;
    rowsPerPage?: number;
    currentPageNumber?: number;
    onRowsPerPageChange?: (rowsCount: number, e: ChangeEvent) => void;
    onPageChange?: (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      pageNumber: number,
    ) => void;
  };

export const ReportTable = <K extends ITableBodyItem>({
  headCells,
  tableUniqueKey,
  data,
  isDataLoading,
  count,
  rowsPerPage,
  currentPageNumber,
  onPageChange,
  onRowsPerPageChange,
  summaryData,
  order,
  orderBy,
  onSort,
  onTableRowClick,
  onRowCheckboxClick,
  heightWithTabs,
  fullHeight = true,
  disablePagination = false,
  sx,
  ...rest
}: IReportTableProps<K>) => {
  return (
    <TableComponent
      heightWithTabs={heightWithTabs}
      tableUniqueKey={tableUniqueKey}
      sx={{
        height: (isDataLoading || !data.length) && fullHeight ? '100%' : 'auto',
        flexGrow: 1,
        ...sx,
      }}
      {...rest}
    >
      {isDataLoading && <MainContentLoader />}
      <TableContent
        fullHeight={fullHeight}
        data={data}
        headCells={headCells}
        isDataLoading={isDataLoading}
        order={order}
        orderBy={orderBy}
        onSort={onSort}
        onRowCheckboxClick={onRowCheckboxClick}
        onTableRowClick={onTableRowClick}
      >
        {summaryData?.length &&
          summaryData.map((item) => (
            <TableSummary key={item.title} {...item} />
          ))}
      </TableContent>

      {!disablePagination && (
        <TableBottom>
          <Pagination
            count={count ?? 0}
            rowsPerPage={rowsPerPage ?? ROWS_PER_PAGE_DEFAULT}
            currentPageNumber={currentPageNumber ?? PAGE_DEFAULT}
            onPageChange={onPageChange ?? noop}
            onRowsPerPageChange={onRowsPerPageChange ?? noop}
          />
        </TableBottom>
      )}
    </TableComponent>
  );
};
