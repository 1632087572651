import { JSONSchemaType } from 'ajv';

import { i18n } from '../utils';

import { ISyncImporting } from '../types/cardinal-health';

export const syncImportingScheme: JSONSchemaType<ISyncImporting> = {
  type: 'object',
  required: ['siteUuid', 'dateStart', 'dateEnd', 'tableType'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteUuid: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
      },
      errorMessage: {
        minItems: i18n.t('error.validation.at_least_one'),
      },
    },
    dateStart: {
      type: 'string',
      format: 'date',
      default: '',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        format: i18n.t('error.validation.date'),
      },
    },
    dateEnd: {
      type: 'string',
      format: 'date',
      default: '',
      errorMessage: {
        format: i18n.t('error.validation.date'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    tableType: {
      type: 'string',
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
