import React from 'react';

import { useSiteRelationInclusion } from 'src/modules/utils/hooks/sites';

export const useAreaFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useCallback(
    (siteId?: number) => ({
      filter: {
        include: [siteRelationInclusion],
        ...(siteId ? { where: { siteId } } : {}),
      },
    }),
    [siteRelationInclusion],
  );
};
