import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'src/config';
import {
  AnyObject,
  DataObjectWithCount,
  IListWithSummary,
} from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';

interface IProps {
  siteId?: number;
  category?: string;
  year?: number;
  week?: number;
  supervisorId?: number;
}

export const NfiIncentiveTeamLeads: React.FC<IProps> = ({
  siteId,
  week,
  year,
  category,
  supervisorId,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'asc' as const,
    orderBy: 'siteId',
  };

  const where = React.useMemo(
    () => ({
      parentSiteId: siteId,
      year,
      week,
      supervisorId,
      incentiveArea: category,
    }),
    [siteId, year, week, category, supervisorId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    DataObjectWithCount<IListWithSummary<AnyObject>>
  >(paths.NFI_WEEKLY_INCENTIVE_TEAM_LEADS, {
    data: {
      items: [],
      summary: [],
    },
    count: 0,
  });
  const computedItems = React.useMemo(
    () =>
      data.data.items.map((item) => ({
        ...item,
      })),
    [data.data.items],
  );

  const showData = Boolean(siteId && year && week);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'incentiveArea',
        label: t('payboard.incentive.area'),
        orderConfig: { orderBy: 'incentiveArea' },
      },
      {
        id: 'supervisorName',
        label: t('employees.supervisor'),
        orderConfig: { orderBy: 'supervisorName' },
      },
      {
        id: 'site',
        label: t('payboard.site_name'),
        orderConfig: { orderBy: 'site' },
      },
      {
        id: 'parentSiteName',
        label: t('payboard.parentSiteName'),
        orderConfig: { orderBy: 'parentSiteName' },
      },
      {
        id: 'site',
        label: t('payboard.site_name'),
        orderConfig: { orderBy: 'site' },
      },
      {
        id: 'parentSiteId',
        label: t('payboard.parentSiteId'),
        orderConfig: { orderBy: 'parentSiteId' },
      },
      {
        id: 'client',
        label: t('users.client_name'),
        orderConfig: { orderBy: 'client' },
      },
      {
        id: 'year',
        label: t('payboard.year'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'week',
        label: t('payboard.week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'yearWeek',
        label: t('payboard.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'incentivePrice',
        label: t('pricing.incentivePrice'),
        orderConfig: { orderBy: 'incentivePrice' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData: showData ? fetchData : undefined,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        where,
        order: [`${orderBy} ${order}`],
      }),
      [order, orderBy, where],
    ),
    localization: React.useMemo(
      () =>
        data.data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.data.summary, t],
    ),
  });

  return (
    <>
      {!showData && (
        <RequiredFiltersAlert i18nKey="common.select_site_year_week_alert" />
      )}
      {showData && (
        <>
          <ReportTable
            data={computedItems as any}
            count={data.count}
            summaryData={data.data.summary}
            isDataLoading={isDataLoading}
            tableUniqueKey={paths.NFI_WEEKLY_INCENTIVE_TEAM_LEADS}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        </>
      )}
    </>
  );
};
