import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { IFilterData, IWhere } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createSiteImportingSettingsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { map } from 'lodash';
import ClientKeysComboBox from 'src/components/Formik/comboboxes-with-entities/ClientKeysCombobox';
import { createSiteImportingSettingScheme } from 'src/modules/schemes';

const defaultTableType = 'production' as const;

const initialValues = {
  // Required properties
  clientKey: '',
  siteUuid: '',
  productionRepositoryKey: '',
  costPlusByExternalHoursServiceKey: '',
  weeklyCpuKey: '',
  customCostPlusKey: '',
  transformerKey: '',
  aggregationKey: '',
  fileRegex: '',
  tableType: defaultTableType,
  bucket: '',
  transformOnSync: true,
  isLocalInfile: false,
};

interface ICreateImportingSettingProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateImportingSetting = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateImportingSettingProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createSiteImportingSettingScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createSiteImportingSettingsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('site-importing-settings.create_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <SitesComboBox
                id="siteUuid"
                fieldAsId="uuid"
                required={true}
                formik={formik}
                label={t('site-importing-settings.site')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <ClientKeysComboBox
                id="clientKey"
                required={true}
                formik={formik}
                label={t('site-importing-settings.clientKey')}
              />
            </FormFieldContainer>

            {map(
              [
                'productionRepositoryKey',
                'weeklyCpuKey',
                'customCostPlusKey',
                'transformerKey',
                'aggregationKey',
                'costPlusByExternalHoursServiceKey',
                'fileRegex',
                'bucket',
                'tableType',
                'hoursRepositoryKey',
                'googleCloudKeyPath',
              ],
              (key) => (
                <FormFieldContainer key={key}>
                  <FormikTextField
                    variant="outlined"
                    required={
                      ![
                        'hoursRepositoryKey',
                        'googleCloudKeyPath',
                        'costPlusByExternalHoursServiceKey',
                      ].includes(key)
                    }
                    fullWidth
                    id={key}
                    label={t(`site-importing-settings.${key}`)}
                    name={key}
                    formik={formik}
                  />
                </FormFieldContainer>
              ),
            )}

            <FormFieldContainer>
              <FormikCheckbox
                id="transformOnSync"
                formik={formik}
                label={t('site-importing-settings.transformOnSync')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="isLocalInfile"
                formik={formik}
                label={t('site-importing-settings.isLocalInfile')}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
